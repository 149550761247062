import { styled } from '../../../../Layout/ThemeProvider/ThemeProvider';
import { ComponentSize } from '../../../ComponentSize';
import { buttonDisabledStyles, buttonLookStyles, buttonSizeStyles } from '../mixins';
import { ButtonLook } from './Button';

type Props = {
  look: ButtonLook;
  size: ComponentSize;
  secondary: boolean;
  tertiary: boolean;
  disabled: boolean;
};

export const ButtonElement = styled.button<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  margin: .5rem;
  border: 0;
  border-radius: 0.8rem;

  font-family: ${({ theme }) => theme.fonts.quicksandBold}, sans-serif;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white};

  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;

  > svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: .9rem;
  }

  ${props => buttonSizeStyles(props.size)}
  ${props => buttonLookStyles({
  look: props.look,
  theme: props.theme,
  tertiary: props.tertiary,
  secondary: props.secondary,
  disabled: props.disabled,
})}
  ${props => buttonDisabledStyles(props.disabled)}
`;
