import * as React from 'react';
import { noop } from '../../../../../helpers/noop';
import { ComponentSize } from '../../../ComponentSize';
import { ButtonElement } from './Button-styled';

const classNames = require('classnames');

export type ButtonLook = 'default' | 'flat' | 'outline' | 'flat-transparent' | 'outline-transparent';

export interface ButtonProps {
  secondary?: boolean;
  tertiary?: boolean;
  look?: ButtonLook;
  size?: ComponentSize;
  IconComponent?: any;
  spinner?: boolean;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  'data-testid'?: string;
  children?: React.ReactNode;

  onClick?: (params: any) => any;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    look, size, spinner, loading, disabled, secondary, tertiary,
    className, style, type, onClick, children, IconComponent,
    ...htmlButtonElementProps
  } = props;

  const buttonClassName = classNames({
    disabled,
    spinner,
  }, className);

  return (
    <ButtonElement
      ref={ref}
      style={style}
      className={buttonClassName}
      type={type}
      look={look || 'default'}
      size={size || 'medium'}
      secondary={secondary || false}
      tertiary={tertiary || false}
      disabled={disabled || false}
      onClick={(!loading && !disabled && onClick) ? onClick : noop}
      data-testid={props['data-testid']}
      {...htmlButtonElementProps}
    >
      {IconComponent && (
        <IconComponent/>
      )}
      {children}
    </ButtonElement>
  );
});
