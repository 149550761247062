import * as React from 'react';
// noinspection TsLint
import MDSpinner from 'react-md-spinner';
import styled from 'styled-components';

interface SpinnerProps {
  style?: any;
  containerStyle?: any;
  className?: string;
  singleColor?: string;
  size?: number;
}

const SpinnerContainer = styled.span`
  overflow: hidden;
`;

export const Spinner: React.FC<SpinnerProps> =
  ({ style = {}, singleColor = '#03a9f4', size = 18, className, containerStyle }) => (
    <SpinnerContainer
      className={className}
      style={containerStyle}
    >
      <MDSpinner
        style={style}
        size={size}
        singleColor={singleColor}
      />
    </SpinnerContainer>
  );
